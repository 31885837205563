import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import { getProductos } from '../../../api/productos';
import { Delete } from '@mui/icons-material';
import { UseAlert } from '../../../components/newAlert/NewAlert';
import { createDetalleMaterial, getDetalleMaterial, updateDetalleMaterial } from '../../../api/materiales';

const ViewMaterialByInstalador = React.memo(({ material, onClose }: any) => {
  const [productos, setProductos] = useState<any[]>([]);
  const [detalleMaterial, setDetalleMaterial] = useState<any[]>([]);
  const { NewNotification } = UseAlert();

  const fetchProductos = useCallback(async () => {
    const response = await getProductos();
    if (response.isOk) {
      setProductos(response.data);
    }
  }, []);

  useEffect(() => {
    fetchProductos();
  }, [fetchProductos]);

  useEffect(() => {
    if (material.Mt_Id) {
      fetchDetalleMaterial();
    }
  }, [material.Mt_Id]);

  const fetchDetalleMaterial = useCallback(async () => {
    const response = await getDetalleMaterial(material.Mt_Id);
    if (response.isOk) {
      console.log(response.data);
      setDetalleMaterial(response.data);
    }
  }, [setDetalleMaterial, detalleMaterial]);

  const handleAddDetalleMaterial = useCallback(async (e: any) => {
    const productoId = e.target.value;

    //Primero buscamos que no exista el producto en el detalle
    const exist = detalleMaterial.find((x) => x.MtRt_Pr_Id === productoId);
    if (exist) {
      NewNotification('El producto ya se encuentra en la lista', 'warning');
      return;
    }
    

    const dataSend = {
      MtRt_Mt_Id: material.Mt_Id,
      MtRt_Pr_Id: productoId,
      MtRt_Inv_Inicial: 0,
      MtRt_Adicional_Lunes: 0,
      MtRt_Consumo_Lunes: 0,
      MtRt_Adicional_Martes: 0,
      MtRt_Consumo_Martes: 0,
      MtRt_Adicional_Miercoles: 0,
      MtRt_Consumo_Miercoles: 0,
      MtRt_Adicional_Jueves: 0,
      MtRt_Consumo_Jueves: 0,
      MtRt_Adicional_Viernes: 0,
      MtRt_Consumo_Viernes: 0,
      MtRt_Adicional_Sabado: 0,
      MtRt_Consumo_Sabado: 0,
      MtRt_Inv_Fisico: 0,
    };

    await createDetalleMaterial(dataSend).then((response: any) => {
      if (response.isOk) {
        NewNotification('Producto agregado correctamente', 'success');
        fetchDetalleMaterial();
      }
    });
  }, [detalleMaterial, productos, material.Mt_Id, NewNotification, fetchDetalleMaterial]);

  const handleRowUpdate = useCallback(async (row: any) => {
    await updateDetalleMaterial(row.MtRt_Id, row).then((response: any) => {
      if (response.isOk) {
        NewNotification('Producto actualizado correctamente', 'success');
        fetchDetalleMaterial();
      }
    });
  }, [fetchDetalleMaterial, NewNotification]);

  const handleDeleteRow = useCallback((row: any) => {
    console.log(row);
  }, []);

  const columns: GridColDef[] = [
    { field: 'Pr_Nombre', headerName: 'Producto', flex: 1, editable: false, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Inv_Inicial', headerName: 'Inicial', width: 60, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Adicional_Lunes', headerName: 'Ad', width: 30, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Consumo_Lunes', headerName: 'Cons', width: 55, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Adicional_Martes', headerName: 'Ad', width: 30, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Consumo_Martes', headerName: 'Cons', width: 55, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Adicional_Miercoles', headerName: 'Ad', width: 30, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Consumo_Miercoles', headerName: 'Cons', width: 55, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Adicional_Jueves', headerName: 'Ad', width: 30, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Consumo_Jueves', headerName: 'Cons', width: 55, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Adicional_Viernes', headerName: 'Ad', width: 30, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Consumo_Viernes', headerName: 'Cons', width: 55, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Adicional_Sabado', headerName: 'Ad', width: 30, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Consumo_Sabado', headerName: 'Cons', width: 55, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Inv_Fisico', headerName: 'Inventario', width: 85, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Total', headerName: 'Total', width: 85, editable: false, sortable: false, filterable: false, hideable: false },
    { field: 'MtRt_Diferencia', headerName: 'Dif', width: 85, editable: false, sortable: false, filterable: false, hideable: false },
    {
      field: 'DeleteButton',
      headerName: 'Eliminar',
      width: 80,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteRow(params.row)}>
          <Delete />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
      hideable: false,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Lunes',
      headerName: 'Lunes',
      children: [{ field: 'MtRt_Adicional_Lunes' }, { field: 'MtRt_Consumo_Lunes' }],
    },
    {
      groupId: 'Martes',
      headerName: 'Martes',
      children: [{ field: 'MtRt_Adicional_Martes' }, { field: 'MtRt_Consumo_Martes' }],
    },
    {
      groupId: 'Miércoles',
      headerName: 'Miércoles',
      children: [{ field: 'MtRt_Adicional_Miercoles' }, { field: 'MtRt_Consumo_Miercoles' }],
    },
    {
      groupId: 'Jueves',
      headerName: 'Jueves',
      children: [{ field: 'MtRt_Adicional_Jueves' }, { field: 'MtRt_Consumo_Jueves' }],
    },
    {
      groupId: 'Viernes',
      headerName: 'Viernes',
      children: [{ field: 'MtRt_Adicional_Viernes' }, { field: 'MtRt_Consumo_Viernes' }],
    },
    {
      groupId: 'Sábado',
      headerName: 'Sábado',
      children: [{ field: 'MtRt_Adicional_Sabado' }, { field: 'MtRt_Consumo_Sabado' }],
    },
  ];

  return (
    <div style={{height:'80vh'}}>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth size="small">
            <InputLabel>Producto</InputLabel>
            {/* Se usa el ID como value en lugar del objeto */}
            <Select value={detalleMaterial.length ? detalleMaterial[0].MtRt_Pr_Id : ''} onChange={handleAddDetalleMaterial} label="Producto">
              {productos.map((producto) => (
                <MenuItem key={`cmb-${producto.Pr_Id}`} value={producto.Pr_Id}>
                  {producto.Pr_Nombre} {producto.Un_Nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              getRowId={(row) => row.MtRt_Id}
              columnGroupingModel={columnGroupingModel}
              rows={detalleMaterial}
              columns={columns}
              processRowUpdate={handleRowUpdate}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  textAlign: 'center',
                },
                '& .MuiDataGrid-columnGroupsHeader': {
                  display: 'flex',
                  justifyContent: 'center',
                },
                '& .MuiDataGrid-columnGroupHeaderCell': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default ViewMaterialByInstalador;
